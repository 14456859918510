<template>
	<div class="box">
		<div class="flex" style="justify-content: space-between">
			<Title title="我的资源列表" />
			<button class="add_but" @click="goAdd">添加资源</button>
		</div>
		<div class="ct_box">
			<orderNav @navTab="pubNavTab" :navId="$store.state.pubNav" />
			<router-view></router-view>
			<el-dialog title="被拒绝原因" :append-to-body="true" :visible.sync="Link" :show-close="false">
				<p>{{ reasonItem.reason }}</p>
			</el-dialog>
			<el-dialog title="编辑媒体" :append-to-body="true" :visible.sync="updateShow" :show-close="false">

			</el-dialog>
		</div>
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import { mapState } from 'vuex'
import orderNav from '../../components/orderNav.vue'
export default {
	components: {
		Title,
		orderNav
	},
	computed: {
		...mapState(['AddRs', 'AddRsId', 'active'])
	},
	mounted() {
		this.$store.commit('childTab', '我的资源')
		// this.$store.state.active = 1

	},
	data() {
		return {
			Link: false,
			reasonText: '', //申请原因
			reasonItem: {},
			updateShow: false, //编辑窗口
			updateItem: {}
		}
	},
	methods: {
		
		goAdd() {
			this.$router.push({
				path: '/user/AddResource/News'
			})
		},
		pubNavTab(id) {
			this.$store.state.pubNav = id
			switch (id) {
				case 1:
					this.$router.push('/user/PublishingMedia/pubNews')
					break;
				case 2:
					this.$router.push('/user/PublishingMedia/pubWeMedia')
					break;
				case 3:
					this.$router.push('/user/PublishingMedia/pubPaperMedia')
					break;
				case 4:
					this.$router.push('/user/PublishingMedia/pubOfficialAccountsMedia')
					break;
				case 5:
					this.$router.push('/user/PublishingMedia/pubWbMedia')
					break;
				case 6:
					this.$router.push('/user/PublishingMedia/pubRedbookMedia')
					break;
				case 7:
					this.$router.push('/user/PublishingMedia/pubShortVideoMedia')
					break;

				default:
					break;
			}
		},

	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/MediaOrder.scss';

::v-deep .el-input__inner {
	height: 38px;
}
</style>